<template>
	<div v-if="versionColor" class="color-multiple">
		<div class="color">
			<div class="color__wrapper">
				<div class="color__circle" :style="backgroundColorObject">
					<img
						src="@/assets/icons/check-color.svg"
						class="color__circle__check"
					/>
					<div
						v-if="
							color.length &&
							color[0].discount &&
							color[0].discount.value
						"
						class="color__tag-discount"
					>
						<p class='discount-tag'>
							-{{color[0].discount.percent}}%
						</p>
					</div>
				</div>
				<div class="color__text">
					<div class='color__text__wrapper'>
						<p class="color__text__name">
							{{ versionColor?.color?.name }}
						</p>
						<p class="color__text__sku">
							SKU: {{ versionColor?.code }}
						</p>
						<p class="color__text__stock" v-if="isOutStock">Agotado</p>
					</div>
					<div class='color__tags'>
						<SbTag
							v-show='versionColor.is_new'
							background='linear-gradient(125.21deg, #c288e5 34.93%, #7723a1 125.04%)'
							text='Nuevo'
							width='90px'
							height='32px'
							font-size='11px'
							font-weight='400'
							padding='6px 9px'
						/>
						<SbTag
							v-show='versionColor.is_top_seller'
							type='color-best-seller'
							background='#000000'
							text='Top seller'
							width='90px'
							height='32px'
							font-size='11px'
							font-weight='400'
							padding='6px 9px'
						/>
					</div>
				</div>
			</div>
		</div>
		<SbCartProductControlQuantity
			:quantity="versionColor.quantity + versionColor.quantityPresale"
			:show-control-manual="true"
			justifyContent="flex-end"
			width="auto"
			:control-decrease-disabled="controlDecreaseDisabled"
			:control-increase-disabled="controlIncreaseDisabled"
			@quantity-decrease="quantityDecrease"
			@quantity-increase="quantityIncrease"
			@quantity-changed-manually="quantityChangedManually"
		/>
	</div>
</template>
<script lang="ts">
import {
	PropType,
	computed,
	defineComponent,
	onMounted,
	ref,
	watchEffect,
} from 'vue'
import SbTag from '@/components/SbTag.vue'
import SbCartProductControlQuantity from '@/components/cart/SbCartProductControlQuantity.vue'
import { productIsPresale } from '@/services/useCartManager'
// import { useAlert, paramsAlert } from '@/services/useAlert'
import { Color, VersionColor } from '@/types'

export default defineComponent({
	name: 'SbProductColorMultiple',
	props: {
		color: {
			type: Array as PropType<Color[] | []>,
			default: () => [],
		},
		name: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
	},
	components: {
		SbCartProductControlQuantity,
		SbTag,
	},
	emits: [
		'onQuantityDecrease',
		'onQuantityIncrease',
		'onQuantityChangedManually',
	],
	setup(props, { emit }) {
		// const { presentAlert } = useAlert()
		const versionColor = ref<VersionColor>()
		// const quantity = ref(versionColor.value.quantity)
		const controlDecreaseDisabled = ref(true)
		const controlIncreaseDisabled = ref(false)
		const backgroundColorObject = ref()

		const quantityDecrease = () =>
			emit('onQuantityDecrease', versionColor.value)
		const quantityIncrease = () =>
			emit('onQuantityIncrease', versionColor.value)
		const quantityChangedManually = (manualQuantity: number) =>
			emit(
				'onQuantityChangedManually',
				versionColor.value,
				manualQuantity
			)

		const isOutStock = computed(() => {
			if (productIsPresale.value) {
				if (versionColor?.value?.excluded_from_presale) {
					return versionColor?.value?.stock ? false : true
				}

				return false
			} else {
				if (versionColor.value && versionColor.value.stock <= 0) {
					return true
				}

				return false
			}
		})

		watchEffect(() => {
			const versionQuantityAvailable =
				versionColor.value?.quantityAvailable
			const versionStock = versionColor.value?.stock || 0

			if (productIsPresale.value) {
				if (versionColor?.value?.excluded_from_presale) {
					controlDecreaseDisabled.value =
						versionStock <= 0 ? true : false
					controlIncreaseDisabled.value =
						versionStock <= 0 || versionQuantityAvailable === 0
							? true
							: false
				} else {
					controlDecreaseDisabled.value = false
					controlIncreaseDisabled.value = false
				}
			} else {
				controlDecreaseDisabled.value = versionStock <= 0 ? true : false
				controlIncreaseDisabled.value =
					versionStock <= 0 || versionQuantityAvailable === 0
						? true
						: false
			}
		})

		const setVersion = (color: any): void => {
			versionColor.value = {
				...color,
				quantity: 0,
				quantityPresale: 0,
				quantityAvailable: color.stock,
				related: [],
			}
		}

		onMounted(() => {
			setVersion(props.color[0])
			if (props.color[0].color) {
				if (props.color[0].color.image) {
					backgroundColorObject.value = {
						'background-image':
							'url(' + props.color[0]?.color.image + ')',
					}
				} else {
					backgroundColorObject.value = {
						background: props.color[0]?.color.color,
					}
				}
			} else {
				versionColor.value = undefined
			}
		})

		return {
			// quantity,
			versionColor,
			quantityDecrease,
			quantityIncrease,
			quantityChangedManually,
			controlDecreaseDisabled,
			controlIncreaseDisabled,
			backgroundColorObject,
			isOutStock,
		}
	},
})
</script>
<style lang="scss" scoped>
.color-multiple {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: relative;
	padding: 1px 0 1px 1px;
}

.color-multiple--disabled::after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: red;
}

.color {
	width: 100%;
	padding-right: 8px;
}

.color__wrapper {
	display: flex;
	align-items: center;
}
.color__circle {
	position: relative;
	min-height: 35px;
	min-width: 35px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: ease all 0.1s;
	outline: 1px solid #cccccc;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.color__tag-discount {
	position: absolute;
	left: -4px;
	top: -16px;

	.discount-tag {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 25px;
		height: 25px;
		background: #e82c2c;
		border-radius: 125px;
		color: #fff;
		font-size: 8px;
		font-weight: 500;
		padding: 3px;
		text-wrap-mode: nowrap;
	}
}
.color__input {
	display: none;
}
.color__input:checked + .color__circle {
	border: 1.5px solid #000;
}
.color__circle__check {
	display: none;
}
.color__input:checked + .color__circle > .color__circle__check {
	display: block;
}
.color__text {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	margin-left: 16px;
	-moz-user-select: all;
	-webkit-user-select: all;
	-ms-user-select: all;
	user-select: all;
	flex-direction: column;

	.color__text__wrapper {
		display: flex;
		flex-direction: column;
	}

	@media (min-width: 576px) {
		flex-direction: row;
		align-items: center;
	}
}
.color__text__name {
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	-moz-user-select: text;
	-webkit-user-select: text;
	-ms-user-select: text;
	user-select: text;
	margin: 5px 0px;
}

.color__text__sku {
	font-size: 13px;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 1px;
	margin: 5px 0px;
	color: #ccc;
}

.color__text__stock {
	font-size: 13px;
	color: #e82c2c;
	font-weight: 500;
	margin: 5px 0px;
}
.color__tags {
	display: flex;
	gap: 5px;

	.tag {
		@media (max-width: 576px) {
			font-size: 10px;
			padding: 0px 0px;
			height: 24px;
			width: 78px;
		}
	}
}
@media (min-width: 400px) {
	.color__circle {
		min-height: 48px;
		min-width: 48px;
	}
}
</style>
